{
  "name": "idhub",
  "version": "1.0.72",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0 --port 4444",
    "build": "ng build",
    "build:production": "ng build --configuration production",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "format": "prettier --write --config ./.prettierrc src/**/*{.ts,.scss,.html}",
    "format:check": "prettier --check .",
    "lint:scss": "stylelint 'src/**/**.scss'",
    "lint:ts": "tsc --noEmit && eslint 'src/**/**.ts'"
  },
  "husky": {
    "hooks": {
      "pre-commit": "npm --no-git-tag-version version patch && git add package.json"
    }
  },
  "private": true,
  "dependencies": {
    "@angular-eslint/builder": "^18.0.1",
    "@angular-eslint/eslint-plugin": "^18.0.1",
    "@angular-eslint/eslint-plugin-template": "^18.0.1",
    "@angular-eslint/schematics": "^18.0.1",
    "@angular-eslint/template-parser": "^18.0.1",
    "@angular/animations": "^18.0.3",
    "@angular/common": "^18.0.3",
    "@angular/compiler": "^18.0.3",
    "@angular/core": "^18.0.3",
    "@angular/forms": "^18.0.3",
    "@angular/localize": "^18.0.3",
    "@angular/platform-browser": "^18.0.3",
    "@angular/platform-browser-dynamic": "^18.0.3",
    "@angular/router": "^18.0.3",
    "@assecods/design-system-lib": "^18.0.39",
    "@ng-bootstrap/ng-bootstrap": "16",
    "@ng-select/ng-select": "12",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.8",
    "@types/lodash-es": "^4.17.9",
    "@types/qrcode": "^1.5.2",
    "@typescript-eslint/parser": "^7.2.0",
    "bootstrap": "^5.3.0",
    "lodash-es": "^4.17.21",
    "ng2-file-upload": "^5.0.0",
    "ngx-device-detector": "^7.0.0",
    "ngx-mask": "15.1.5",
    "rxjs": "~6.5.3",
    "tslib": "^2.0.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.3",
    "@angular/cli": "^18.0.3",
    "@angular/compiler-cli": "^18.0.3",
    "@types/jasmine": "~3.6.0",
    "@types/jest": "^29.5.12",
    "@types/node": "^20.10.5",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "codelyzer": "^6.0.0",
    "eslint": "^8.57.0",
    "eslint-config-airbnb": "^19.0.4",
    "eslint-config-airbnb-angular": "^1.0.0",
    "eslint-config-airbnb-typescript": "^17.1.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-angular": "^4.1.0",
    "eslint-plugin-import": "^2.22.1",
    "eslint-plugin-jsx-a11y": "^6.4.1",
    "eslint-plugin-prettier": "^5.1.2",
    "husky": "^9.0.11",
    "jasmine-core": "~3.6.0",
    "jasmine-spec-reporter": "~5.0.0",
    "jest": "^29.7.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.0.3",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "prettier": "^3.1.1",
    "protractor": "~7.0.0",
    "stylelint": "^13.13.1",
    "stylelint-config-sass-guidelines": "^8.0.0",
    "ts-node": "~8.3.0",
    "tslint": "~6.1.0",
    "tslint-config-prettier": "^1.18.0",
    "typescript": "5.4.5"
  },
  "engines": {
    "node": ">=18.13",
    "yarn": ">=4.5",
    "npm": "Please use yarn instead of NPM to install dependencies"
  },
  "packageManager": "yarn@4.5.0"
}
